<template>
    <div class="col-xl-12 mt-4">
        <div class="block block-rounded">
            <div
                class="block-header block-header-default d-flex justify-content-between align-items-center"
            >
                <h3 class="block-title">
                    Summary Table with Embedded Cards (Last 7 Days)
                </h3>
                <div class="d-flex align-items-center">
                    <select
                        v-model="itemsPerPage"
                        @change="fetchData"
                        class="form-select"
                        id="itemsPerPage"
                    >
                        <option :value="5">5</option>
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option> <!-- Corrected value from 20 to 50 -->
                    </select>
                    <span
                        class="ms-3"
                        @click="toggleSortOrder"
                        style="cursor: pointer"
                    >
                        <span v-if="sortOrder === 'desc'">🔽</span>
                        <span v-else>🔼</span>
                    </span>
                </div>
            </div>
            <div class="block-content block-content-full">
                <div class="table-responsive">
                    <table class="table table-bordered table-vcenter">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Properties Trend</th>
                                <th>Leads Trend</th>
                                <th>Clients Trend</th>
                                <th>Sales Trend</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in realData" :key="user.user_id">
                                <td>
                                    <!-- User name with link to profile -->
                                    <a
                                        :href="getUserProfileUrl(user.user_id)"
                                        class="text-primary"
                                    >
                                        {{ user.user_name }}
                                    </a>
                                </td>

                                <!-- Properties Chart Card -->
                                <td>
                                    <a class="block block-rounded block-link-pop" href="javascript:void(0)">
                                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                                            <div>
                                                <Chart
                                                    type="line"
                                                    :data="getChartData(user.user_data, 'All_properties')"
                                                    :options="sparklineOptions"
                                                    style="width: 90px; height: 40px;"
                                                />
                                            </div>
                                            <dl class="ms-3 text-end mb-0">
                                                <dt class="h3 fw-extrabold mb-0">
                                                    {{ getTotalCount(user.user_data, 'All_properties') }}
                                                </dt>
                                                <dd class="fs-sm fw-medium text-muted mb-0">
                                                    Properties
                                                </dd>
                                            </dl>
                                        </div>
                                    </a>
                                </td>

                                <!-- Leads Chart Card -->
                                <td>
                                    <a class="block block-rounded block-link-pop" href="javascript:void(0)">
                                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                                            <div>
                                                <Chart
                                                    type="line"
                                                    :data="getChartData(user.user_data, 'All_leads')"
                                                    :options="sparklineOptions"
                                                    style="width: 90px; height: 40px;"
                                                />
                                            </div>
                                            <dl class="ms-3 text-end mb-0">
                                                <dt class="h3 fw-extrabold mb-0">
                                                    {{ getTotalCount(user.user_data, 'All_leads') }}
                                                </dt>
                                                <dd class="fs-sm fw-medium text-muted mb-0">
                                                    Leads
                                                </dd>
                                            </dl>
                                        </div>
                                    </a>
                                </td>

                                <!-- Clients Chart Card -->
                                <td>
                                    <a class="block block-rounded block-link-pop" href="javascript:void(0)">
                                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                                            <div>
                                                <Chart
                                                    type="line"
                                                    :data="getChartData(user.user_data, 'all_clients')"
                                                    :options="sparklineOptions"
                                                    style="width: 90px; height: 40px;"
                                                />
                                            </div>
                                            <dl class="ms-3 text-end mb-0">
                                                <dt class="h3 fw-extrabold mb-0">
                                                    {{ getTotalCount(user.user_data, 'all_clients') }}
                                                </dt>
                                                <dd class="fs-sm fw-medium text-muted mb-0">
                                                    Clients
                                                </dd>
                                            </dl>
                                        </div>
                                    </a>
                                </td>

                                <!-- Sales Chart Card -->
                                <td>
                                    <a class="block block-rounded block-link-pop" href="javascript:void(0)">
                                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                                            <div>
                                                <Chart
                                                    type="line"
                                                    :data="getChartData(user.user_data, 'all_sales')"
                                                    :options="sparklineOptions"
                                                    style="width: 90px; height: 40px;"
                                                />
                                            </div>
                                            <dl class="ms-3 text-end mb-0">
                                                <dt class="h3 fw-extrabold mb-0">
                                                    {{ getTotalCount(user.user_data, 'all_sales') }}
                                                </dt>
                                                <dd class="fs-sm fw-medium text-muted mb-0">
                                                    Sales
                                                </dd>
                                            </dl>
                                        </div>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <nav>
                    <ul class="pagination justify-content-center">
                        <li
                            class="page-item"
                            :class="{ disabled: currentPage === 1 }"
                        >
                            <a
                                class="page-link"
                                @click.prevent="changePage(currentPage - 1)"
                                href="#"
                                >Previous</a
                            >
                        </li>
                        <li
                            class="page-item"
                            :class="{ active: page === currentPage }"
                            v-for="page in lastPage"
                            :key="page"
                        >
                            <a
                                class="page-link"
                                @click.prevent="changePage(page)"
                                href="#"
                                >{{ page }}</a
                            >
                        </li>
                        <li
                            class="page-item"
                            :class="{ disabled: currentPage === lastPage }"
                        >
                            <a
                                class="page-link"
                                @click.prevent="changePage(currentPage + 1)"
                                href="#"
                                >Next</a
                            >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Chart from "primevue/chart";
import axios from "axios";

const realData = ref([]);
const itemsPerPage = ref(5);
const currentPage = ref(1);
const lastPage = ref(1);
const sortOrder = ref("desc");

const toggleSortOrder = () => {
    sortOrder.value = sortOrder.value === "desc" ? "asc" : "desc";
    fetchData();
};

const sparklineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
    },
    scales: {
        x: { display: false },
        y: { display: false },
    },
};

const fetchData = async () => {
    try {
        const response = await axios.get("/get-summary-table", {
            params: {
                page: currentPage.value,
                per_page: itemsPerPage.value,
                sort_order: sortOrder.value,
            },
        });
        realData.value = response.data.data;
        lastPage.value = response.data.pagination.last_page;
    } catch (error) {
        console.error("Error fetching summary table data:", error);
    }
};

const changePage = (page) => {
    if (page >= 1 && page <= lastPage.value) {
        currentPage.value = page;
        fetchData();
    }
};

const getChartData = (userData, field) => {
    const labels = userData.map((day) => day.date);
    const data = userData.map((day) => day[field]);

    return {
        labels,
        datasets: [
            {
                data,
                borderColor: "#e04f1a",
                fill: false,
                tension: 0.4,
            },
        ],
    };
};

const getTotalCount = (userData, field) => {
    return userData.reduce((total, day) => total + (day[field] || 0), 0);
};

const getUserProfileUrl = (userId) => {
    return `/profile/${userId}`;
};


onMounted(() => {
    fetchData();
});

onBeforeUnmount(() => {
});


</script>

<style scoped>
.table-borderless th,
.table-borderless td {
    border: none;
}

.table-vcenter td {
    vertical-align: middle;
}

.text-primary {
    color: #007bff;
    text-decoration: none;
}

.text-primary:hover {
    text-decoration: underline;
}

/* Additional Styles for the New Card Structure */
.block {
    transition: transform 0.2s;
}

.block:hover {
    transform: translateY(0px);
}

.block-link-pop {
    cursor: pointer;
}

.block-rounded {
    border-radius: 8px;
}

.block-content-full {
    padding: 10px;
}

.fs-sm {
    font-size: 0.875rem;
}

.fw-extrabold {
    font-weight: 800;
}

.fw-medium {
    font-weight: 500;
}

.text-muted {
    color: #6c757d !important;
}

.ms-3 {
    margin-left: 1rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.h3 {
    font-size: 1.75rem;
}

</style>
