<template>
    <div class="row justify-content-center">
        <!-- Sales and Commission Chart -->
        <div class="col-12 mb-4"> <!-- Full width regardless of screen size -->
            <div class="block block-rounded">
                <div class="block-header block-header-default">
                    <h5 class="block-title">Sales and Commission Comparison</h5>
                </div>
                <div class="block-content block-content-full">
                    <Chart type="bar" :data="chartData" :options="chartOptions" style="height: 400px;" />
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const chartData = ref();
const chartOptions = ref();
const props = defineProps({
    entity: {
        type: String,
        required: true, // Ensure the entity prop is passed
    },
    userId: {
        type: [String, Number, null],
        default: null,
    },
});
onMounted(async () => {
    const data = await fetchSalesData();
    chartData.value = setChartData(data);
    chartOptions.value = setChartOptions();
});

const fetchSalesData = async () => {
    try {
        const response = await axios.get("/get-dashboard-salesdata", {
            params: {
                entity: props.entity,
                user_id: props.userId,
             },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching sales data:", error);
        return { thisYear: [], lastYear: [] };
    }
};

const setChartData = (data) => {
    const documentStyle = getComputedStyle(document.documentElement);

    return {
        labels: [
            'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December',
        ],
        datasets: [
            // Commission Line Charts
            {
                type: 'line',
                label: 'Commission Amount Last Year',
                borderColor: 'hsl(240, 70%, 80%)', // Pale blue for last year
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                data: data.lastYear.commissionAmounts,
            },
            {
                type: 'line',
                label: 'Commission Amount This Year',
                borderColor: 'hsl(180, 70%, 80%)', // Pale cyan for this year
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                data: data.thisYear.commissionAmounts,
            },

            // Sales Bar Charts
            {
                type: 'bar',
                label: 'Sales Amount Last Year',
                backgroundColor: 'hsl(0, 70%, 80%)', // Pale grayish-red for last year
                data: data.lastYear.salesAmounts,
                borderColor: 'white',
            },
            {
                type: 'bar',
                label: 'Sales Amount This Year',
                backgroundColor: 'hsl(30, 70%, 80%)', // Pale orange for this year
                data: data.thisYear.salesAmounts,
                borderColor: 'white',
                borderWidth: 2,
            },

        ],

    };
};

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--p-text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');
    const surfaceBorder = documentStyle.getPropertyValue('--p-content-border-color');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
}
</script>
