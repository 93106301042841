<template>
    <div class="card flex justify-center">
        <AvatarGroup>
            <Avatar 
                v-for="(user, index) in displayedUsers" 
                :key="index"
                :image="user.avatar" 
                :title="user.name"
                size="large" 
                shape="circle" 
            />
            <Avatar 
                v-if="overflowCount > 0" 
                :label="'+' + overflowCount" 
                shape="circle" 
                size="large" 
            />
        </AvatarGroup>
    </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import AvatarGroup from 'primevue/avatargroup';
import Avatar from 'primevue/avatar';

// Define props
const props = defineProps({
    users: Array
});

// Maximum number of avatars to show before showing the "+N" avatar
const maxAvatars = 4;

// Computed property to get displayed users
const displayedUsers = computed(() => {
    return props.users.slice(0, maxAvatars);
});

// Computed property to get the overflow count
const overflowCount = computed(() => {
    const total = props.users.length;
    return total > maxAvatars ? total - maxAvatars : 0;
});
</script>

<style>
.card {
    padding: 1rem;
}
</style>
