<template>
    <Toast />

    <button type="button" icon="pi pi-share-alt" class="btn btn-sm btn-alt-info me-2 mb-2" label="Share" @click="toggle">
        <i class="fa fa-fw fa-share me-1"></i>
        Share
    </button>
    <Popover ref="op">
        <div class="flex flex-col gap-4 w-[25rem] text-sm">
            <div>
                <span class="font-medium mb-2">Public link </span><span class="font-small text-muted">- Valid for 1 Week</span>
                <Inplace @open="generateLink">
                    <template #display>
                        <Button 
                            type="button" 
                            icon="pi pi-link" 
                            class="btn-sm w-full"
                            label="Generate a link" 
                            size="small" 
                            severity="secondary"/>
                    </template>
                    <template #content>
                        <InputGroup>
                            <InputText :value="shareableLink" readonly class="w-full" size="small" />
                            <InputGroupAddon>
                                <i class="pi pi-copy" size="small" @click="copyToClipboard"></i>
                            </InputGroupAddon>
                        </InputGroup>
                    </template>
                </Inplace>
            </div>
            <Button 
                type="button" 
                icon="pi pi-download" 
                class="btn-sm w-full mb-2" 
                label="Download PDF" 
                size="small"
                @click="downloadPDF" />
        </div>
    </Popover>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from "primevue/usetoast";
import axios from 'axios';

const toast = useToast();
const op = ref();
const shareableLink = ref('');
const router = useRouter();

const generateLink = async () => {
    try {
        const propertyId = document.getElementById('property_id')?.textContent;
        if (propertyId) {
            const response = await axios.get(`/generate-signed-url/${propertyId}`);
            shareableLink.value = response.data.url;
        }
    } catch (error) {
        console.error('Failed to generate the signed link:', error);
    }
};

const toggle = (event) => {
    op.value.toggle(event);
};

const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink.value)
        .then(() => {
            op.value.hide(); // Hide the popover
            toast.add({
                severity: 'info',
                summary: 'Info',
                detail: 'Link copied to clipboard',
                life: 3000
            });
        })
        .catch(err => {
            console.error('Failed to copy link:', err);
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to copy link',
                life: 3000
            });
        });
};

const downloadPDF = () => {
    let propertyId = document.getElementById('property_id')?.textContent;
    if (propertyId) {
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'PDF is ready to download',
            life: 3000
        });
        const url = router.resolve({
            name: 'partner.property.print',
            params: {
                property_id: propertyId
            }
        }).href;
        window.open(url, '_blank');
    } else {
        console.error('Property ID not found');
    }
};
</script>

<style scoped>
    @tailwind utilities;
</style>
