<template>
    <div class="block block-rounded">
        <div class="block-header block-header-default">
            <h3 class="block-title"> {{t('title_result')}}
                <div v-if="loading" class="spinner-border spinner-border-sm text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span hidden id="reusltCount">({{ roles . length }})</span>
            </h3>
        </div>
        <div class="block-content block-content-full">
            <div class="dataTables_wrapper dt-bootstrap5 no-footer">
                <div class="row dt-row">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-vcenter dataTable no-footer" aria-describedby="table_info">
                                <thead>
                                    <tr class="text-center">
                                        <th class="text-center sorting sorting_asc" style="width: 80px;" tabindex="0"
                                            aria-sort="ascending">#
                                        </th>
                                        <th>{{t('table_role')}}</th>
                                        <th>{{t('table_users')}}</th>
                                        <th>{{t('table_created_by')}}</th>
                                        <th>{{t('table_created_at')}}</th>
                                        <th class="d-none d-sm-table-cell">{{ tableActions }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(role, index) in roles" :key="role._id" class="text-center">
                                        <td class="text-center">{{ index + 1 }}</td>
                                        <td class="fw-semibold fs-sm">{{ role . role_title }}</td>
                                        <td class="text-center">
                                            <div class="d-flex justify-content-center"
                                                @click="showUserList(role.users)">
                                                <AvatarGroup>
                                                    <!-- Display the first 2 avatars -->
                                                    <Avatar v-for="(user, i) in role.users.slice(0, 5)"
                                                        :key="i" :image="user.avatar"
                                                        :title="user.name" size="small" shape="circle" />

                                                    <!-- Show the label if there are more than 2 users -->
                                                    <Avatar v-if="role.users.length > 5"
                                                        :label="'+' + (role.users.length - 5)" shape="circle"
                                                        size="small" />
                                                </AvatarGroup>
                                            </div>
                                        </td>
                                        <td>{{ role . created_by ? role . created_by . name : '' }}</td>
                                        <td>{{ new Date(role . created_at) . toLocaleDateString() }}</td>
                                        <td>
                                            <a v-if="role.is_root || (!role.major && !role.isDefault)"
                                                :href="getActionUrl(role._id)"
                                                class="btn btn-sm btn-alt-secondary me-1">
                                                <i class="fa-solid fa-pen"></i>
                                            </a>
                                            <a v-if="role.deletable || (!role.is_root && !role.major && !role.isDefault)"
                                                @click="deleteRole(role._id)" class="btn btn-sm btn-alt-danger me-1">
                                                <i class="fa-solid fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Backdrop -->
        <div v-if="showModal" class="modal-backdrop" @click="closeModal"></div>
        <!-- User List Modal -->
        <div v-if="showModal" class="modal fade show" tabindex="-1" style="display: block;"
            aria-labelledby="userListModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="userListModalLabel">{{t('table_users')}}</h5>
                        <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li v-for="(user, index) in selectedUsers" :key="index" class="list-group-item">
                                <img :src="user.avatar" class="rounded-circle me-2"
                                    style="width: 40px; height: 40px;" />
                                {{ user . name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmPopup></ConfirmPopup>

</template>

<script>
    import axios from 'axios';
    import en from '../../../lang/en.json';
    import ar from '../../../lang/ar.json';
    import {
        useConfirm
    } from "primevue/useconfirm";


    export default {
        data() {
            return {
                translations: {},
                roles: [],
                loading: false,
                showModal: false,
                selectedUsers: []
            };
        },
        methods: {
            loadTranslations() {
                const currentLang = window.currentLang; // Use the globally set language
                this.translations = currentLang === 'ar' ? ar : en; // Load appropriate translation
            },
            t(key) {
                return this.translations[key] || key; // Translation fallback to key
            },
            fetchRoles() {
                this.loading = true;
                axios.get("get-roles/")
                    .then((response) => {
                        this.roles = response.data;
                        this.loading = false;
                        document.getElementById("reusltCount").hidden = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading = false;
                        document.getElementById("reusltCount").hidden = false;
                    });
            },
            getActionUrl(id) {
                return "role/" + id;
            },
            deleteRole(id) {
                // Implement the delete functionality here
                // Make the POST request using Axios
               
                console.log("Delete role with ID:", id);
                axios.post(`role/delete-role/${id}`)
                    .then(response => {
                        // Handle successful response
                        console.log('Response data:', response.data);
                        Swal.fire({
                            title: "Deleted!",
                            text: "The Project has been deleted.",
                            icon: "success"
                        });
                        fetchRoles();
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error:', error);
                    });
            },
            showUserList(users) {
                this.selectedUsers = users;
                this.showModal = true;
            },
            closeModal() {
                this.showModal = false;
                this.selectedUsers = [];
            }
        },
        mounted() {
            this.loadTranslations();
            this.fetchRoles();
        }
    };
</script>
<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1040;
        /* Adjust as needed to sit behind the modal but above other content */
    }
</style>
