<!-- resources/js/components/DashboardCards.vue -->
<template>
    <div class="row items-push">
        <!-- Properties Card -->
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
            <div class="block block-rounded d-flex flex-column h-100 mb-0">
                <div
                    class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center"
                >
                    <dl class="mb-0">
                        <dt class="fs-3 fw-bold">
                            <span v-if="isLoading">
                                <div
                                    class="spinner-grow spinner-grow-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </span>
                            <span v-else-if="hasError">-</span>
                            <span v-else>{{ counts.properties_count }}</span>
                        </dt>
                        <dd class="fs-sm fw-medium text-muted mb-0">
                            Properties
                        </dd>
                    </dl>
                    <div class="item item-rounded-lg bg-body-light">
                        <i
                            class="far fa-solid fs-3 fa-house-chimney text-primary"
                        ></i>
                    </div>
                </div>
                <div class="bg-body-light rounded-bottom">
                    <a
                        class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
                        href="/properties/working"
                    >
                        <span>View Properties</span>
                        <i
                            class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"
                        ></i>
                    </a>
                </div>
            </div>
        </div>

        <!-- Leads Card -->
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
            <div class="block block-rounded d-flex flex-column h-100 mb-0">
                <div
                    class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center"
                >
                    <dl class="mb-0">
                        <dt class="fs-3 fw-bold">
                            <span v-if="isLoading">
                                <div
                                    class="spinner-grow spinner-grow-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </span>
                            <span v-else-if="hasError">-</span>
                            <span v-else>{{ counts.leads_count }}</span>
                        </dt>
                        <dd class="fs-sm fw-medium text-muted mb-0">Leads</dd>
                    </dl>
                    <div class="item item-rounded-lg bg-body-light">
                        <i
                            class="far fa-solid fs-3 fa-bullhorn text-primary"
                        ></i>
                    </div>
                </div>
                <div class="bg-body-light rounded-bottom">
                    <a
                        class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
                        href="/leads/working"
                    >
                        <span>View Leads</span>
                        <i
                            class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"
                        ></i>
                    </a>
                </div>
            </div>
        </div>

        <!-- Clients Card -->
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
            <div class="block block-rounded d-flex flex-column h-100 mb-0">
                <div
                    class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center"
                >
                    <dl class="mb-0">
                        <dt class="fs-3 fw-bold">
                            <span v-if="isLoading">
                                <div
                                    class="spinner-grow spinner-grow-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </span>
                            <span v-else-if="hasError">-</span>
                            <span v-else>{{ counts.clients_count }}</span>
                        </dt>
                        <dd class="fs-sm fw-medium text-muted mb-0">Clients</dd>
                    </dl>
                    <div class="item item-rounded-lg bg-body-light">
                        <i
                            class="far fa-solid fs-3 fa-address-book text-primary"
                        ></i>
                    </div>
                </div>
                <div class="bg-body-light rounded-bottom">
                    <a
                        class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
                        href="/clients/all"
                    >
                        <span>View Clients</span>
                        <i
                            class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"
                        ></i>
                    </a>
                </div>
            </div>
        </div>

        <!-- Users Card -->
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
            <div class="block block-rounded d-flex flex-column h-100 mb-0">
                <div
                    class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center"
                >
                    <dl class="mb-0">
                        <dt class="fs-3 fw-bold">
                            <span v-if="isLoading">
                                <div
                                    class="spinner-grow spinner-grow-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </span>
                            <span v-else-if="hasError">-</span>
                            <span v-else>{{ counts.users_count }}</span>
                        </dt>
                        <dd class="fs-sm fw-medium text-muted mb-0">Users</dd>
                    </dl>
                    <div class="item item-rounded-lg bg-body-light">
                        <i class="far fa-solid fs-3 fa-users text-primary"></i>
                    </div>
                </div>
                <div class="bg-body-light rounded-bottom">
                    <a
                        class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
                        href="/resources/staff"
                    >
                        <span>View Users</span>
                        <i
                            class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"
                        ></i>
                    </a>
                </div>
            </div>
        </div>

        <!-- Sales Amount Card -->
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
            <div class="block block-rounded d-flex flex-column h-100 mb-0">
                <div
                    class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center"
                >
                    <dl class="mb-0">
                        <dt class="fs-3 fw-bold">
                            <span v-if="isLoading">
                                <div
                                    class="spinner-grow spinner-grow-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </span>
                            <span v-else-if="hasError">-</span>
                            <span v-else>{{ counts.sales_amount }}</span>
                        </dt>
                        <dd class="fs-sm fw-medium text-muted mb-0">Sales</dd>
                    </dl>
                    <div class="item item-rounded-lg bg-body-light">
                        <i
                            class="far fa-solid fs-3 fa-dollar-sign text-success"
                        ></i>
                    </div>
                </div>
                <div class="bg-body-light rounded-bottom">
                    <a
                        class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
                        href="/reports/deal-summry-report/all"
                    >
                        <span>View Sales</span>
                        <i
                            class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"
                        ></i>
                    </a>
                </div>
            </div>
        </div>

        <!-- Commission Amount Card -->
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
            <div class="block block-rounded d-flex flex-column h-100 mb-0">
                <div
                    class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center"
                >
                    <dl class="mb-0">
                        <dt class="fs-3 fw-bold">
                            <span v-if="isLoading">
                                <div
                                    class="spinner-grow spinner-grow-sm text-primary"
                                    role="status"
                                >
                                    <span class="visually-hidden"
                                        >Loading...</span
                                    >
                                </div>
                            </span>
                            <span v-else-if="hasError">-</span>
                            <span v-else>{{ counts.commission_amount }}</span>
                        </dt>
                        <dd class="fs-sm fw-medium text-muted mb-0">
                            Commission
                        </dd>
                    </dl>
                    <div class="item item-rounded-lg bg-body-light">
                        <i
                            class="far fa-solid fs-3 fa-chart-line text-warning"
                        ></i>
                    </div>
                </div>
                <div class="bg-body-light rounded-bottom">
                    <a
                        class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
                        href="/reports/deal-summry-report/all"
                    >
                        <span>View Earning</span>
                        <i
                            class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"
                        ></i>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <!-- Optional: Global error message -->
    <div v-if="hasError" class="alert alert-danger mt-3">
        {{ errorMessage }}
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import axios from "axios";

const counts = ref({
    properties_count: 0,
    leads_count: 0,
    clients_count: 0,
    users_count: 0,
    sales_amount: 0,
    commission_amount: 0,
});
const props = defineProps({
    entity: {
        type: String,
        required: true, // Ensure the entity prop is passed
    },
    userId: {
        type: [String, Number, null],
        default: null,
    },
});
const isLoading = ref(true);
const hasError = ref(false);
const errorMessage = ref("");

const fetchCounts = async () => {
    try {
        const response = await axios.get("/get-dashboard-cards", {
            withCredentials: true,
            params: { entity: props.entity,user_id: props.userId, },
        });
        counts.value.properties_count = response.data.properties_count;
        counts.value.leads_count = response.data.leads_count;
        counts.value.clients_count = response.data.clients_count;
        counts.value.users_count = response.data.users_count;
        counts.value.sales_amount = response.data.sales_amount;
        counts.value.commission_amount = response.data.commission_amount;
        hasError.value = false;
    } catch (error) {
        hasError.value = true;
        errorMessage.value = "Failed to load dashboard counts.";
    } finally {
        isLoading.value = false;
    }
};


onMounted(() => {
    fetchCounts();
});

onBeforeUnmount(() => {
});
</script>

<style scoped>
.block {
    /* Add any additional styles if necessary */
}
</style>
