<template>
    <div class="container-fluid px-4">
        <!-- Lead, Property, and Client Status Section -->
        <div class="row">
            <!-- Property Status Chart -->
            <div class="col-xl-6 col-md-12 ">
                <div class="block block-rounded">
                    <div class="block-header block-header-default">
                        <h5 class="block-title">Property Status Over Time</h5>
                    </div>
                    <div class="block-content block-content-full">
                        <Chart type="line" :data="propertyData" :options="chartOptions" class="w-full h-[15rem]" />
                    </div>
                </div>
            </div>
            <!-- Lead Status Chart -->
            <div class="col-xl-6 col-md-12">
                <div class="block block-rounded">
                    <div class="block-header block-header-default">
                        <h5 class="block-title">Lead Status Over Time</h5>
                    </div>
                    <div class="block-content block-content-full">
                        <Chart type="line" :data="leadData" :options="chartOptions" class="w-full h-[15rem]" />
                    </div>
                </div>
            </div>
            <!-- Client Status Chart -->
            <div class="col-xl-6 col-md-12 ">
                <div class="block block-rounded">
                    <div class="block-header block-header-default">
                        <h5 class="block-title">Client Status Over Time</h5>
                    </div>
                    <div class="block-content block-content-full">
                        <Chart type="line" :data="clientData" :options="chartOptions" class="w-full h-[15rem]" />
                    </div>
                </div>
            </div>

        </div>

        <!-- Top Projects Section -->
        <div class="row justify-content-center">
            <!-- Top 10 Projects Polar Chart (Available Properties) -->
            <div class="col-xl-6 col-md-12 mb-4">
                <div class="block block-rounded">
                    <div class="block-header block-header-default">
                        <h5 class="block-title">Top 10 Projects by Available Properties</h5>
                    </div>
                    <div class="block-content block-content-full">
                        <Chart type="polarArea" :data="polarData" :options="polarChartOptions" class=" h-[10rem]" />
                    </div>
                </div>
            </div>

            <!-- Top 10 Projects Polar Chart (Available Leads) -->
            <div class="col-xl-6 col-md-12 mb-4">
                <div class="block block-rounded">
                    <div class="block-header block-header-default">
                        <h5 class="block-title">Top 10 Projects by Available Leads</h5>
                    </div>
                    <div class="block-content block-content-full">
                        <Chart type="polarArea" :data="polarLeadsData" :options="polarChartOptions"
                            class="w-full h-[15rem]" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Chart from "primevue/chart";
import axios from "axios";

const propertyData = ref({ datasets: [] });
const leadData = ref({ datasets: [] });
const clientData = ref({ datasets: [] });
const polarData = ref({ datasets: [{ data: [] }] });
const polarLeadsData = ref({ datasets: [{ data: [] }] });

const startDate = ref('');
const endDate = ref('');
const props = defineProps({
    entity: {
        type: String,
        required: true, // Ensure the entity prop is passed
    },
    userId: {
        type: [String, Number, null],
        default: null,
    },
});

const fetchChartData = async () => {
    try {
        const response = await axios.get("/get-dashboard-stats", {
            params: { start_date: startDate.value, end_date: endDate.value, entity: props.entity,user_id: props.userId, },
        });
        const { data, top_projects, top_projects_leads } = response.data;

        const labels = data.map((entry) => entry.date);

        const statuses = {
            properties: [
                { key: "active_properties", label: "Active Properties" },
                { key: "inactive_properties", label: "Inactive Properties" },
                { key: "progress_properties", label: "In Progress Properties" },
                { key: "hold_properties", label: "Hold Properties" },
                { key: "completed_properties", label: "Completed Properties" },
                { key: "external_sale_properties", label: "External Sale Properties" },
                { key: "deleted_properties", label: "Deleted Properties" },
            ],
            leads: [
                { key: "active_leads", label: "Active Leads" },
                { key: "inactive_leads", label: "Inactive Leads" },
                { key: "progress_leads", label: "In Progress Leads" },
                { key: "hold_leads", label: "Hold Leads" },
                { key: "completed_leads", label: "Completed Leads" },
                { key: "external_sale_leads", label: "External Sale Leads" },
                { key: "deleted_leads", label: "Deleted Leads" },
            ],
            clients: [
                { key: "new_clients", label: "New Clients" },
                { key: "interested_clients", label: "Interested Clients" },
                { key: "notinterested_clients", label: "Not Interested Clients" },
                { key: "hotcase_clients", label: "Hot Case Clients" },
                { key: "deleted_clients", label: "Deleted Clients" },
            ],
        };

        propertyData.value = {
            labels,
            datasets: statuses.properties.map((status, index) => ({
                label: status.label,
                data: data.map((entry) => entry[status.key] || 0),
                borderColor: `hsl(${index * 60}, 70%, 80%)`, // Pale colors
                tension: 0.4,
            })),
        };

        leadData.value = {
            labels,
            datasets: statuses.leads.map((status, index) => ({
                label: status.label,
                data: data.map((entry) => entry[status.key] || 0),
                borderColor: `hsl(${index * 60}, 70%, 80%)`, // Pale colors
                tension: 0.4,
            })),
        };

        clientData.value = {
            labels,
            datasets: statuses.clients.map((status, index) => ({
                label: status.label,
                data: data.map((entry) => entry[status.key] || 0),
                borderColor: `hsl(${index * 60}, 70%, 80%)`, // Pale colors
                tension: 0.4,
            })),
        };

        polarData.value = {
            labels: top_projects.map((project) => project.project_name),
            datasets: [
                {
                    data: top_projects.map((project) => project.available_properties || 0),
                    backgroundColor: top_projects.map((_, index) => `hsl(${index * 60}, 70%, 80%)`), // Pale colors
                },
            ],
        };

        polarLeadsData.value = {
            labels: top_projects_leads.map((project) => project.project_name),
            datasets: [
                {
                    data: top_projects_leads.map((project) => project.available_leads || 0),
                    backgroundColor: top_projects_leads.map((_, index) => `hsl(${index * 60}, 70%, 80%)`), // Pale colors
                },
            ],
        };

    } catch (error) {
        console.error("Error fetching chart data:", error);
    }
};

const updateDateRange = (newStartDate, newEndDate) => {
    startDate.value = newStartDate;
    endDate.value = newEndDate;
    fetchChartData();
};

// Auto-update data every minute
onMounted(() => {
    const today = new Date();
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
    updateDateRange(oneMonthAgo.toISOString().split('T')[0], today.toISOString().split('T')[0]);


    document.addEventListener("dateRangeChanged", (event) => {
        const { startDate, endDate } = event.detail;
        updateDateRange(startDate, endDate);
    });
});

onBeforeUnmount(() => {
    document.removeEventListener("dateRangeChanged", updateDateRange);
});
</script>

<style scoped>
.card {
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

hr {
    border: 1px solid #ccc;
}

h3 {
    margin-bottom: 20px;
    text-align: center;
}

.card-header.bg-primary {
    background-color: hsl(220, 50%, 85%);
    /* Pale blue */
}

.card-header.bg-success {
    background-color: hsl(120, 50%, 85%);
    /* Pale green */
}

.card-header.bg-info {
    background-color: hsl(180, 50%, 85%);
    /* Pale cyan */
}

.card-header.bg-warning {
    background-color: hsl(45, 50%, 85%);
    /* Pale yellow */
}

.card-header.bg-danger {
    background-color: hsl(0, 50%, 85%);
    /* Pale red */
}
</style>
