import { createRouter, createWebHistory } from 'vue-router';
import PropertyPrint from '../js/components/properties/ShareButton.vue'; // Example component for print

const routes = [
    { path: '/properties/print-property/:property_id', name: 'partner.property.print', component: PropertyPrint },
    // Add other routes here
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
