// Function to format a number into a short representation
export function formatNumberShort(number, precision = 2) {
    const suffixes = ["", "K", "M", "B", "T", "Q", "Qi"]; // Extend suffixes array
    let suffixIndex = 0;

    while (Math.abs(number) >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
    }

    let formattedNumber = number.toFixed(precision).replace(/\.?0+$/, '');

    return formattedNumber + suffixes[suffixIndex];
}

export function formatInputNumber(inputId) {
    const numberInput = document.getElementById(inputId);
    const formattedNumber = formatNumberShort(parseFloat(numberInput.value) || 0);

    // Determine which span to update based on inputId
    switch (inputId) {
        case 'property_price_input':
            document.getElementById("formatted_property_price").innerText = formattedNumber;
            break;
        case 'down_payment_input':
            document.getElementById("formatted_down_payment").innerText = formattedNumber;
            break;
        case 'installments_input':
            document.getElementById("formatted_installments").innerText = formattedNumber;
            break;
        case 'grandTotal':
            document.getElementById("formatted_grandTotal").innerText = formattedNumber;
            break;
        case 'min_budget':
            document.getElementById("formatted_min_budget").innerText = formattedNumber;
            break;
        case 'max_budget':
            document.getElementById("formatted_max_budget").innerText = formattedNumber;
            break;
        case 'min_budget_update':
            document.getElementById("formatted_min_budget_update").innerText = formattedNumber;
            break;
        case 'max_budget_update':
            document.getElementById("formatted_max_budget_update").innerText = formattedNumber;
            break;
    }
}

export function validateBudget(minFieldId, minErrorId, maxFieldId, maxErrorId) {
    const minBudget = 100000;
    const minField = document.getElementById(minFieldId);
    const maxField = document.getElementById(maxFieldId);

    const minError = document.getElementById(minErrorId);
    const maxError = document.getElementById(maxErrorId);
    const budgetError = document.getElementById('budget_error');

    // Validate min_budget
    if (minField.value < minBudget) {
        minField.setCustomValidity('The minimum budget must be at least 100,000.');
    } else {
        minError.style.display = 'none';
        minField.setCustomValidity('');
    }

    // Validate max_budget
    if (maxField.value < minBudget) {
        maxField.setCustomValidity('The maximum budget must be at least 100,000.');
    } else {
        maxError.style.display = 'none';
        maxField.setCustomValidity('');
    }

    // Ensure max_budget is greater than min_budget
    if (parseInt(maxField.value) <= parseInt(minField.value)) {
        maxField.setCustomValidity('The maximum budget must be greater than the minimum budget.');
    } else {
        budgetError.style.display = 'none';
        maxField.setCustomValidity('');
    }
}
