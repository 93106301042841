<template>
    <div class="card flex justify-center">
        <Toast />
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue';
import { useToast } from "primevue/usetoast";

const toast = useToast();

const showSuccessToast = (message) => {
    toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
};

const showErrorToast = (message) => {
    toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
};

// Listen to custom events when the component is mounted
onMounted(() => {
    window.addEventListener('status-update-success', (event) => {
        showSuccessToast(event.detail.message);
    });

    window.addEventListener('status-update-failure', (event) => {
        showErrorToast(event.detail.message);
    });
});

// Clean up event listeners when the component is unmounted
onBeforeUnmount(() => {
    window.removeEventListener('status-update-success', () => {});
    window.removeEventListener('status-update-failure', () => {});
});
</script>
