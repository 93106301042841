<template>
    <div class="block block-rounded block-mode-loading" id="teamsContainer">
        <div class="block-header block-header-default">
            <h3 class="block-title">
                {{ t("title_result") }}
                <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm text-dark"
                    role="status"
                >
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span>({{ teams.length }})</span>
            </h3>
        </div>
        <div class="block-content block-content-full">
            <div class="dataTables_wrapper dt-bootstrap5 no-footer">
                <div class="row dt-row">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table
                                class="table table-vcenter dataTable no-footer"
                                aria-describedby="table_info"
                            >
                                <thead>
                                    <tr class="text-center">
                                        <th
                                            @click="sortTable('_id')"
                                            class="sorting"
                                        >
                                            #
                                        </th>
                                        <th
                                            @click="sortTable('title')"
                                            class="sorting"
                                        >
                                            {{ t("table_team") }}
                                        </th>
                                        <th
                                            @click="sortTable('members')"
                                            class="sorting"
                                        >
                                            {{ t("table_members") }}
                                        </th>
                                        <th
                                            @click="sortTable('properties')"
                                            class="sorting"
                                        >
                                            {{ t("table_properties") }}
                                        </th>
                                        <th
                                            @click="sortTable('requests')"
                                            class="sorting"
                                        >
                                            {{ t("table_requests") }}
                                        </th>
                                        <th
                                            @click="sortTable('clients')"
                                            class="sorting"
                                        >
                                            {{ t("table_clients") }}
                                        </th>
                                        <th
                                            @click="sortTable('created_by')"
                                            class="sorting"
                                        >
                                            {{ t("table_created_by") }}
                                        </th>
                                        <th
                                            @click="sortTable('created_at')"
                                            class="sorting"
                                        >
                                            {{ t("table_created_at") }}
                                        </th>
                                        <th class="d-none d-sm-table-cell"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(team, index) in sortedTeams"
                                        :key="team._id"
                                        class="text-center"
                                    >
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="fw-semibold fs-sm">
                                            {{ team.title }}
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="d-flex justify-content-center"
                                                @click="
                                                    showUserList(team.users)
                                                "
                                            >
                                                <AvatarGroup>
                                                    <Avatar
                                                        v-for="(
                                                            user, i
                                                        ) in team.users.slice(
                                                            0,
                                                            5
                                                        )"
                                                        :key="i"
                                                        :image="user.avatar"
                                                        :title="user.name"
                                                        size="small"
                                                        shape="circle"
                                                    />
                                                    <Avatar
                                                        v-if="
                                                            team.users.length >
                                                            5
                                                        "
                                                        :label="
                                                            '+' +
                                                            (team.users.length -
                                                                5)
                                                        "
                                                        shape="circle"
                                                        size="small"
                                                    />
                                                </AvatarGroup>
                                            </div>
                                        </td>
                                        <td class="fw-semibold fs-sm">0</td>
                                        <td class="fw-semibold fs-sm">0</td>
                                        <td class="fw-semibold fs-sm">0</td>
                                        <td>
                                            {{
                                                team.created_by
                                                    ? team.created_by.name
                                                    : ""
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                new Date(
                                                    team.created_at
                                                ).toLocaleDateString()
                                            }}
                                        </td>
                                        <td>
                                            <a
                                                v-if="!team.isDefault"
                                                @click="openUpdateModal(team)"
                                                class="btn btn-sm btn-alt-secondary me-1"
                                            >
                                                <i class="fa-solid fa-pen"></i>
                                            </a>
                                            <a
                                                v-if="!team.isDefault"
                                                @click="deleteTeam(team._id)"
                                                class="btn btn-sm btn-alt-danger me-1"
                                            >
                                                <i
                                                    class="fa-solid fa-trash"
                                                ></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Backdrop -->
        <div v-if="showModal" class="modal-backdrop" @click="closeModal"></div>
        <!-- User List Modal -->
        <div
            v-if="showModal"
            class="modal fade show"
            tabindex="-1"
            style="display: block"
            aria-labelledby="userListModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="userListModalLabel">
                            Users
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            @click="closeModal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li
                                v-for="(user, index) in selectedUsers"
                                :key="index"
                                class="list-group-item"
                            >
                                <img
                                    :src="user.avatar"
                                    class="rounded-circle me-2"
                                    style="width: 40px; height: 40px"
                                />
                                {{ user.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ConfirmPopup></ConfirmPopup>
    <!-- Store Team Modal -->
    <div
        class="modal modal-lg fade"
        id="storeTeam"
        tabindex="-1"
        aria-labelledby="modal-block-popout"
        style="display: none"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-popout" role="document">
            <div class="modal-content">
                <div class="block block-rounded block-transparent mb-0">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">
                            {{ t("title_create_team") }}
                        </h3>
                        <div class="block-options">
                            <button
                                type="button"
                                class="btn-block-option"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i class="fa fa-fw fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <form @submit.prevent="storeTeam">
                        <div class="block-content fs-sm">
                            <div class="row push">
                                <div class="col">
                                    <!-- Team Title -->
                                    <div class="mb-2">
                                        <label
                                            class="form-label"
                                            for="team_title"
                                            >{{ t("title_team_name") }}</label
                                        >
                                        <input
                                            required
                                            type="text"
                                            class="form-control"
                                            id="team_title"
                                            name="team_title"
                                            v-model="newTeam.title"
                                            placeholder="Team 1, Team 2..."
                                        />
                                    </div>
                                    <!-- Team Members -->
                                    <div class="mb-2">
                                        <label
                                            class="form-label"
                                            for="teamMembers"
                                            >{{
                                                t("title_team_members")
                                            }}</label
                                        >
                                        <div class="container">
                                            <ul class="ks-cboxtags">
                                                <!-- Loop through all users -->
                                                <li
                                                    v-for="user in allUsers"
                                                    :key="user._id"
                                                >
                                                    <!-- Use v-model to bind the checkbox to selectedTeam.members -->
                                                    <input
                                                        type="checkbox"
                                                        :id="
                                                            'checkbox-user' +
                                                            user._id
                                                        "
                                                        v-model="
                                                            newTeam.members
                                                        "
                                                        :value="user._id"
                                                    />
                                                    <label
                                                        :for="
                                                            'checkbox-user' +
                                                            user._id
                                                        "
                                                    >
                                                        <img
                                                            class="img-avatar img-avatar12"
                                                            :src="user.avatar"
                                                        />
                                                        {{ user.name }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="block-content block-content-full text-end bg-body"
                        >
                            <button
                                type="button"
                                class="btn btn-sm btn-alt-secondary me-1"
                                data-bs-dismiss="modal"
                            >
                                {{ t("button_close") }}
                            </button>
                            <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                            >
                                {{ t("button_create") }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Update Team Modal -->
    <div
        class="modal modal-lg fade"
        id="updateTeam"
        tabindex="-1"
        aria-labelledby="modal-block-popout"
        style="display: none"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-popout" role="document">
            <div class="modal-content">
                <div class="block block-rounded block-transparent mb-0">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">Update team</h3>
                        <div class="block-options">
                            <button
                                type="button"
                                class="btn-block-option"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i class="fa fa-fw fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <form @submit.prevent="updateTeam">
                        <div class="block-content block-content-full">
                            <div class="row push">
                                <div class="col">
                                    <!-- Team Title -->
                                    <div class="mb-2">
                                        <label
                                            class="form-label"
                                            for="team_title_update"
                                            >Team Title</label
                                        >
                                        <input
                                            required
                                            v-model="selectedTeam.title"
                                            type="text"
                                            class="form-control"
                                            id="team_title_update"
                                            name="team_title_update"
                                            placeholder="Team 1, Team 2..."
                                        />
                                    </div>
                                    <!-- Team Members -->
                                    <div class="mb-2">
                                        <label
                                            class="form-label"
                                            for="teamMembers"
                                            >Members</label
                                        >
                                        <div class="container">
                                            <ul class="ks-cboxtags">
                                                <!-- Loop through all users -->
                                                <li
                                                    v-for="user in allUsers"
                                                    :key="user._id"
                                                >
                                                    <!-- Use v-model to bind the checkbox to selectedTeam.members -->
                                                    <input
                                                        type="checkbox"
                                                        :id="
                                                            'checkbox-member' +
                                                            user._id
                                                        "
                                                        :value="user._id"
                                                        v-model="
                                                            selectedTeam.members
                                                        "
                                                    />
                                                    <label
                                                        :for="
                                                            'checkbox-member' +
                                                            user._id
                                                        "
                                                    >
                                                        <img
                                                            class="img-avatar img-avatar12"
                                                            :src="user.avatar"
                                                        />
                                                        {{ user.name }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-alt-secondary me-1"
                                    data-bs-dismiss="modal"
                                >
                                    {{ t("button_update") }}
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-primary"
                                >
                                    {{ t("button_update") }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { toRaw } from "vue";
import en from "../../../lang/en.json";
import ar from "../../../lang/ar.json";

export default {
    data() {
        return {
            translations: {},
            teams: [],
            sortColumn: "", // The current column to sort by
            sortDirection: "asc", // Sorting direction: 'asc' or 'desc'
            allUsers: [], // Fetch all users to display in the modal
            loading: false,
            showModal: false,
            selectedUsers: [],
            newTeam: {
                title: "",
                members: [],
            },
            selectedTeam: {
                title: "",
                members: [],
            },
        };
    },
    computed: {
        sortedTeams() {
            return [...this.teams].sort((a, b) => {
                let result = 0;

                if (this.sortColumn === "created_by") {
                    // Handle the nested property for sorting by creator name
                    const nameA = a.created_by?.name || "";
                    const nameB = b.created_by?.name || "";
                    result = nameA.localeCompare(nameB);
                } else if (this.sortColumn) {
                    const valueA = a[this.sortColumn];
                    const valueB = b[this.sortColumn];

                    if (valueA < valueB) result = -1;
                    if (valueA > valueB) result = 1;
                }

                return this.sortDirection === "asc" ? result : -result;
            });
        },
    },
    methods: {
        loadTranslations() {
            const currentLang = window.currentLang; // Use the globally set language
            this.translations = currentLang === "ar" ? ar : en; // Load appropriate translation
        },
        t(key) {
            return this.translations[key] || key; // Translation fallback to key
        },
        fetchTeams() {
            axios
                .get("get-teams/")
                .then((response) => {
                    this.teams = response.data;
                    $("#teamsContainer").removeClass("block-mode-loading");
                })
                .catch((error) => {
                    console.error(error);
                    $("#teamsContainer").removeClass("block-mode-loading");
                });
        },
        sortTable(column) {
            if (this.sortColumn === column) {
                // Toggle the direction if the same column is clicked again
                this.sortDirection =
                    this.sortDirection === "asc" ? "desc" : "asc";
            } else {
                // Start sorting by the new column
                this.sortColumn = column;
                this.sortDirection = "asc";
            }
        },
        openUpdateModal(team) {
            // Parse members into an array if it's a JSON string
            let membersArray = Array.isArray(team.members)
                ? team.members
                : JSON.parse(team.members || "[]");

            // Populate selectedTeam with team data
            this.selectedTeam = {
                ...team,
                members: membersArray, // Ensure it's an array of member IDs
            };
        },
        storeTeam() {
            axios
                .post(`store-team`, this.newTeam)
                .then((response) => {
                    Swal.fire({
                        title: "Created!",
                        text: "The Team has been created.",
                        icon: "success",
                    });
                    this.fetchTeams();
                    this.newTeam = {
                        title: "",
                        members: [],
                    }; // Clear the form after submission
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        },
        updateTeam() {
            axios
                .put(`update-team/${this.selectedTeam._id}`, this.selectedTeam)
                .then((response) => {
                    Swal.fire({
                        title: "Updated!",
                        text: "The Team has been updated.",
                        icon: "success",
                    });
                    this.fetchTeams();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        },
        deleteTeam(id) {
            axios
                .delete(`delete-team/${id}`)
                .then((response) => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "The Team has been deleted.",
                        icon: "success",
                    });
                    this.fetchTeams();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        },
        showUserList(users) {
            this.selectedUsers = users;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedUsers = [];
        },
    },
    mounted() {
        this.loadTranslations();
        this.fetchTeams();
        axios.get("/resources/get-staff").then((response) => {
            this.allUsers = toRaw(response.data.data);
            console.log(toRaw(this.allUsers));
        });
    },
};
</script>

<style>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.ks-cboxtags ul {
    padding-left: 0;
    list-style-type: none;
}

.ks-cboxtags li {
    display: inline;
    padding-right: 10px;
}
</style>
