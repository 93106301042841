<template>
    <div>
        <Select 
            v-model="selectedStatusId" 
            :options="availableStatuses" 
            optionLabel="title" 
            placeholder="Change Status" 
            class="w-full md:w-56" 
            @change="updateStatus"
        />
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import Select from 'primevue/select';
import axios from 'axios';

const props = defineProps({
    propStatus: {
        type: Array,
        required: true
    },
    propertyStatusId: {
        type: Number,
        required: true
    },
    propertyId: {
        type: Number,
        required: true
    }
});

const selectedStatusId = ref(props.propertyStatusId);

const availableStatuses = computed(() => {
    return Object.values(props.propStatus).filter(status => status._id != props.propertyStatusId);
});

const updateStatus = async () => {
    try {
        await axios.post('/partner/property/status/update', {
            prop_id: props.propertyId,
            status_id: selectedStatusId.value,
        }, {
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
            }
        });
        location.reload(); // Reload the page on success
    } catch (error) {
        console.error(error);
    }
};
</script>
