<template>
    <div>
        <DataTable :value="feedbacks" stripedRows v-if="!loading">
            <Column field="avatar" header="Avatar">
                <template #body="slotProps">
                    <img :src="slotProps.data.originator.avatar" alt="Avatar" class="avatar-img">
                </template>
            </Column>
            <Column field="name" header="Name">
                <template #body="slotProps">
                    {{ slotProps.data.originator.name }}
                </template>
            </Column>
            <Column field="entity" header="Entity">
                <template #body="slotProps">
                    <span v-if="slotProps.data.property_id">
                        {{ slotProps.data.entity?.reference || 'N/A' }}
                    </span>
                    <span v-else-if="slotProps.data.lead_id">
                        {{ slotProps.data.entity?.reference || 'N/A' }}
                    </span>
                    <span v-else-if="slotProps.data.client_id">
                        {{ slotProps.data.entity?.reference || 'N/A' }}
                    </span>
                    <span v-else>
                        N/A
                    </span>
                </template>
            </Column>
            <Column field="feedback" header="Feedback">
                <template #body="slotProps">
                    {{ slotProps.data.content }}
                </template>
            </Column>
            <Column field="created_at" header="Created At">
                <template #body="slotProps">
                    {{ new Date(slotProps.data.created_at).toLocaleString() }}
                </template>
            </Column>
        </DataTable>
        <div v-else class="skeleton-container">
            <div class="skeleton-cell" v-for="i in 5" :key="i">
                <Skeleton class="skeleton-item"></Skeleton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Skeleton from 'primevue/skeleton';

const feedbacks = ref([]);
const loading = ref(true);

const fetchUrl = '/feedbacks';

const fetchFeedbacks = async () => {
    try {
        const response = await axios.get(fetchUrl);
        feedbacks.value = response.data;
    } catch (error) {
        console.error('Error fetching feedbacks:', error);
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    fetchFeedbacks();
});
</script>

<style scoped>
.avatar-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.skeleton-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
    margin-top: 10px;
    margin-bottom: 10px;
}

.skeleton-cell {
    width: 100%;
    max-width: 95%; /* Adjust based on table width */
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

</style>
