<template>
    <!-- Full-Screen Galleria -->
    <Galleria 
        v-model:activeIndex="activeIndex" 
        v-model:visible="displayCustom" 
        :value="images" 
        :responsiveOptions="responsiveOptions" 
        :numVisible="7"
        containerStyle="max-width: 850px" 
        :circular="true" 
        :fullScreen="true" 
        :showItemNavigators="true" 
        :showThumbnails="false"
    >
        <template #item="slotProps">
            <img 
                :src="slotProps.item.itemImageSrc" 
                :alt="slotProps.item.alt" 
                style="width: 100%; display: block" 
            />
        </template>
        <template #thumbnail="slotProps">
            <img 
                :src="slotProps.item.thumbnailImageSrc" 
                :alt="slotProps.item.alt" 
                style="display: block" 
            />
        </template>
    </Galleria>

    <!-- Thumbnail Galleria -->
    <Galleria 
        :value="images" 
        :numVisible="5" 
        containerStyle="max-width: 640px" 
        :showThumbnails="false"
        :showIndicators="true" 
        :changeItemOnIndicatorHover="true" 
        :showIndicatorsOnItem="inside" 
        :indicatorsPosition="position"
    >
        <template #item="slotProps">
            <div class="image-wrapper" @click="imageClick(slotProps.index)">
                <img 
                    :src="slotProps.item.itemImageSrc" 
                    :alt="slotProps.item.alt" 
                    class="fixed-image" 
                    style="cursor: pointer" 
                />
            </div>
        </template>
    </Galleria>
</template>

<script setup>
import { ref } from "vue";
import Galleria from 'primevue/galleria';

const props = defineProps({
    images: {
        type: Array,
        required: true
    }
});

const activeIndex = ref(0);
const displayCustom = ref(false);

const responsiveOptions = ref([
    {
        breakpoint: '1300px',
        numVisible: 4
    },
    {
        breakpoint: '575px',
        numVisible: 1
    }
]);

// Open Galleria and set active image
const imageClick = (index) => {
    activeIndex.value = index;
    displayCustom.value = true;
};
</script>

<style scoped>
.image-wrapper {
    width: 100%;
    height: 300px; /* Set the desired fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures that the image doesn't overflow the container */
}

.fixed-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Keeps the image's aspect ratio intact while filling the container */
    display: block;
}
</style>
