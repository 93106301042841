<template>
  <Chip
    :label="name"
    :image="avatar"
    class="small-chip custom-chip"
    @click="navigateToProfile"
  />
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import Chip from 'primevue/chip';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  avatar: {
    type: String,
    required: true,
  },
});

const router = useRouter();

function navigateToProfile() {
  const profileUrl = `/profile/${props.id}`; // Construct URL with the id
  window.location.href = profileUrl; // Redirect to the full URL
  }
</script>

<style scoped>
.custom-chip {
  --p-chip-border-radius: 50px;
  --p-chip-background: #dbdcff; /* Set your desired background color */
  --p-chip-text-color: #fff; /* Set the text color */
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

.custom-chip:hover {
  --p-chip-background: #a1a4e8; /* Change background color on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
  transform: scale(1.03); /* Slightly enlarge the chip on hover */
}
</style>
